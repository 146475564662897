// src/components/Breadcrumbs.js
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from '../styles/Breadcrumbs.module.css';

const Breadcrumbs = () => {
  const { mainCategory, subCategory } = useParams();

  return (
    <div className={styles.breadcrumbs}>
      <Link to="/Home">Главная</Link>
      {mainCategory && (
        <>
          <span> / </span>
          <Link to={`/category/${mainCategory}`}>{mainCategory}</Link>
        </>
      )}
      {subCategory && (
        <>
          <span> / </span>
          <Link to={`/category/:mainCategory/:subCategory${mainCategory}/${subCategory}`}>{subCategory}</Link>
        </>
      )}
    </div>
  );
};

export default Breadcrumbs;
