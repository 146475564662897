import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import { CartProvider } from './components/CartContext';
import Footer from './components/Footer';
import Home from './pages/Home';
import ProductDetail from './pages/ProductDetail';
import Login from './pages/Login';
import Register from './pages/Register';
import Cart from './pages/Cart';
import Category from './components/Category';
// import CategoryList from './components/CategoryList';
import ProductList from './components/ProductList';
import ProductCategory from './components/ProductCategory';
import MainCategories from './components/MainCategories';
import SearchResults from './components/SearchResults';
import './App.css';
import CategoryList from './components/CategoryList';
// import AdminPanel from './components/AdminPanel';





function App() {

  return (
    <CartProvider>
      <Router>
        <div className="App">
          <Header />
          <main>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/product/:id" element={<ProductDetail />} />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/category" element={<CategoryList />} />
              <Route path="/category/:mainCategory/:subCategory" element={<Category />} />
              <Route path="/main-categories" element={<MainCategories />} />
              {/* <Route path="/admin" component={AdminPanel} /> */}
            </Routes>
          </main>

          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
