import React, { useState, useEffect } from 'react';
import products from '../data/products';
import ProductCard from './ProductCard';
import Filters from './Filters';
import styles from '../styles/MainCategories.module.css';

const categoryImage = {
    "Вентиляция и кондиционирование зданий": "/images/category1.webp",
    "Кондиционирование": "/images/category2.webp"
};

const MainCategory = () => {
    // Изначально активируем категорию "Вентиляция и кондиционирование зданий"
    const [selectedCategory, setSelectedCategory] = useState('Вентиляция и кондиционирование зданий');
    const [expandedSubCategories, setExpandedSubCategories] = useState({});
    const [itemsToShow, setItemsToShow] = useState({});
    const [filters, setFilters] = useState({ brands: [], minPrice: '', maxPrice: '' });

    const mainCategories = ['Вентиляция и кондиционирование зданий', 'Кондиционирование'];

    useEffect(() => {
        // Показать подкатегорию по умолчанию для выбранной категории
        setExpandedSubCategories({});  // сброс при изменении категории
        setItemsToShow({});
    }, [selectedCategory]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    const handleToggleExpand = (subCategory) => {
        setExpandedSubCategories(prevState => ({
            ...prevState,
            [subCategory]: !prevState[subCategory]
        }));
        setItemsToShow(prevState => ({
            ...prevState,
            [subCategory]: prevState[subCategory] ? 4 : subCategories[subCategory].length  // логика скрытия/показа
        }));
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    const filteredProducts = products.filter(product => {
        const categoryName = Object.keys(product.categoryName)[0];
        return categoryName === selectedCategory;
    });

    const filteredAndSortedProducts = filteredProducts.filter(product => {
        const matchesBrand = filters.brands.length ? filters.brands.includes(product.brand) : true;
        const matchesPrice = (!filters.minPrice || product.price >= parseFloat(filters.minPrice)) &&
                             (!filters.maxPrice || product.price <= parseFloat(filters.maxPrice));
        return matchesBrand && matchesPrice;
    });

    const subCategories = {};

    filteredAndSortedProducts.forEach(product => {
        const mainCategory = Object.keys(product.categoryName)[0];
        const subCategory = product.categoryName[mainCategory];

        if (!subCategories[subCategory]) {
            subCategories[subCategory] = [];
        }

        subCategories[subCategory].push(product);
    });

    return (
        <div className={styles.mainCategoryContainer}>
            <h2>Каталог товаров</h2>
            <div className={styles.mainCategories}>
                {mainCategories.map(category => (
                    <div key={category} className={styles.categoryBlock} onClick={() => handleCategorySelect(category)}>
                        <h1>{category}</h1>
                        <img src={categoryImage[category]} alt={category} />
                    </div>
                ))}
            </div>
            {selectedCategory && (
                <div className={styles.contentContainer}>
                    <div className={styles.filterColumn}>
                        <Filters products={filteredProducts} onFilterChange={handleFilterChange} className={styles.filtersFilter} />
                    </div>
                    <div className={styles.subCategoryContainer}>
                        {Object.keys(subCategories).map(subCategory => {
                            const itemsToDisplay = itemsToShow[subCategory] || 10;
                            return (
                                <div key={subCategory} className={styles.subCategorySection}>
                                    <h3>{subCategory}</h3>
                                    <div className={styles.productList}>
                                        {subCategories[subCategory]
                                            .slice(0, itemsToDisplay)
                                            .map(product => (
                                                <ProductCard key={product.id} product={product} />
                                            ))}
                                    </div>
                                    <button className={styles.buttonCategory} onClick={() => handleToggleExpand(subCategory)}>
                                        {expandedSubCategories[subCategory] ? 'Скрыть товары' : 'Показать все товары'}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MainCategory;
