import React, { createContext, useState, useEffect } from 'react';

// Создаем контекст
export const CartContext = createContext();

// Провайдер контекста
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    // Загружаем корзину из localStorage при инициализации
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Сохраняем корзину в localStorage при каждом изменении
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // Функция для добавления товара в корзину
  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find(item => item.id === product.id);

      if (existingProduct) {
        // Если товар уже есть в корзине, увеличиваем его количество
        return prevCart.map(item =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        // Если товара нет в корзине, добавляем его с количеством 1
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  // Функция для удаления товара из корзины
  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((product) => product.id !== productId));
  };

  // Функция для изменения количества товара в корзине
  const updateCartItemQuantity = (productId, change) => {
    setCart((prevCart) =>
      prevCart.map(item =>
        item.id === productId
          ? { ...item, quantity: item.quantity + change }
          : item
      ).filter(item => item.quantity > 0) // Удаляем товар, если его количество становится 0
    );
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateCartItemQuantity }}>
      {children}
    </CartContext.Provider>
  );
};
