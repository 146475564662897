import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import products from '../data/products';
import styles from '../styles/CategoryList.module.css';

const CategoryList = () => {
  const navigate = useNavigate();
  const [expandedCategory, setExpandedCategory] = useState(null);

  const categories = {};

  products.forEach(product => {
    const mainCategory = Object.keys(product.categoryName)[0];
    const subCategory = product.categoryName[mainCategory];

    if (!categories[mainCategory]) {
      categories[mainCategory] = [];
    }

    if (!categories[mainCategory].includes(subCategory)) {
      categories[mainCategory].push(subCategory);
    }
  });

  const handleNavigate = (mainCategory, subCategory) => {
    navigate(`/category/${mainCategory}/${subCategory}`);
  };

  const handleToggleExpand = (mainCategory) => {
    setExpandedCategory(prevState => (prevState === mainCategory ? null : mainCategory));
  };

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        {Object.keys(categories).map(mainCategory => (
          <div key={mainCategory} className={styles.navItem}>
            <button
              className={styles.navButton}
              onClick={() => handleToggleExpand(mainCategory)}
            >
              {mainCategory} <span className={styles.arrow}>▼</span>
            </button>
            {expandedCategory === mainCategory && (
              <ul className={styles.subMenu}>
                {categories[mainCategory].map(subCategory => (
                  <li key={subCategory} className={styles.subMenuItem}>
                    <a
                      href={`/category/${mainCategory}/${subCategory}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNavigate(mainCategory, subCategory);
                      }}
                    >
                      {subCategory}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </nav>
    </header>
  );
};

export default CategoryList;
