import React, { useState, useContext } from 'react';
import { CartContext } from '../components/CartContext';
import styles from '../styles/ContactForm.module.css';

function ContactForm() {
  const { cart } = useContext(CartContext);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    paymentMethod: 'credit_card'  // Default payment method
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Calculate total amount
    const totalAmount = cart.reduce((total, product) => {
      const productPrice = parseFloat(product.price) || 0;
      return total + productPrice * product.quantity;
    }, 0);

    // Combine all data into a single order object
    const orderData = {
      ...formData,
      cartItems: cart,
      totalAmount: totalAmount
    };

    // Mock submission logic
    console.log('Order Data:', orderData);

    // Simulate a successful submission
    setIsSubmitted(true);

    // Reset form
    setFormData({
      name: '',
      email: '',
      message: '',
      paymentMethod: 'credit_card'
    });
  };

  return (
    <div className={styles.contactForm}>
      <h2>Форма заказа</h2>
      {isSubmitted ? (
        <p className={styles.successMessage}>Спасибо за Ваш заказ! Мы скоро с Вами свяжимся </p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Имя:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="message">Сообщение:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="paymentMethod">Способы оплаты:</label>
            <select
              id="paymentMethod"
              name="paymentMethod"
              value={formData.paymentMethod}
              onChange={handleChange}
              required
            >
              <option value="credit_card">Кредитная карта</option>
              <option value="paypal">PayPal</option>
              <option value="cash_on_delivery">Оплата при получении</option>
            </select>
          </div>
          <div className={styles.cartSummary}>
            <h3>Ваша корзина:</h3>
            <ul>
              {cart.map((product) => (
                <li key={product.id}>
                  {product.name} - {product.quantity} x {product.price} 
                </li>
              ))}
            </ul>
            <h4>Общая сумма: {cart.reduce((total, product) => total + (parseFloat(product.price) || 0) * product.quantity, 0).toFixed(2)} </h4>

          </div>
          <button type="submit" className={styles.submitButton}>Форма обратной связи</button>
        </form>
      )}
    </div>
  );
}

export default ContactForm;
