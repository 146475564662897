import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import products from '../data/products';
import Breadcrubs from '../components/Breadcrumbs';
import styles from '../styles/ProductDetail.module.css';

const ProductDetail = () => {
  const { id } = useParams();
  const product = products.find(p => p.id === id);
  const [activeMenuItem, setActiveMenuItem] = useState('Описание');
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableProduct, setEditableProduct] = useState({ ...product });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'E') {
        setIsEditMode(prevMode => !prevMode);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!product) {
    return <div>Продукт не найден.</div>;
  }

  const images = [
    editableProduct.image1,
    editableProduct.image2,
    editableProduct.image3,
    editableProduct.image4,
    editableProduct.image5,
  ].filter(Boolean);

  const handleMenuItemClick = (item) => {
    setActiveMenuItem(item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('specifications.')) {
      const key = name.split('.')[1];
      setEditableProduct((prevState) => ({
        ...prevState,
        specifications: {
          ...prevState.specifications,
          [key]: value,
        },
      }));
    } else {
      setEditableProduct({
        ...editableProduct,
        [name]: value,
      });
    }
  };

  const renderContent = () => {
    switch (activeMenuItem) {
      case 'Описание':
        return (
          isEditMode ? 
            <textarea
              name="article"
              value={editableProduct.article}
              onChange={handleChange}
              className={styles.editableTextarea}
            /> : 
            <div>{editableProduct.article}</div>
        );
      case 'Характеристики':
        return (
          isEditMode ? 
            <div>
              {Object.entries(editableProduct.specifications).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}:</strong>
                  <input
                    type="text"
                    name={`specifications.${key}`}
                    value={value}
                    onChange={handleChange}
                    className={styles.editableInput}
                  />
                </div>
              ))}
            </div> :
            <ul className={styles.productUl}>
              {Object.entries(editableProduct.specifications).map(([key, value]) => (
                <li className={styles.productUlLi} key={key}>
                  <strong>{key}:</strong> {value}
                </li>
              ))}
            </ul>
        );
      case 'Оплата':
        return <div>Информация об оплате.</div>;
      case 'Доставка / Самовывоз':
        return <div>Информация о доставке и самовывозе.</div>;
      case 'Монтаж':
        return <div>Информация о монтаже.</div>;
      case 'Инструкция':
        return <div>{editableProduct.instructions}</div>;
      case 'Гарантия':
        return <div>Информация о гарантии.</div>;
      default:
        return null;
    }
  };

  const saveChanges = () => {
    console.log('Изменения сохранены:', editableProduct);
    // Здесь можно реализовать логику сохранения (например, отправку данных на сервер)
    setIsEditMode(false);
  };

  return (
    <div className={styles.productRoot}>
      <Breadcrubs />
      <div className={styles.productContainer}>
        <h1 className={styles.productName}>
          {isEditMode ? 
            <input
              type="text"
              name="name"
              value={editableProduct.name}
              onChange={handleChange}
              className={styles.editableInput}
            /> :
            editableProduct.name}
        </h1>
        <div className={styles.productContent}>
          <div className={styles.productImage}>
            <Carousel
              showThumbs={true}
              thumbWidth={50}
              dynamicHeight={true}
              infiniteLoop={false}
              emulateTouch={true}
              showStatus={false}
              showIndicators={true}
              showArrows={false}
            >
              {images.map((image, index) => (
                <div key={index}>
                  {isEditMode ? (
                    <input
                      type="text"
                      name={`image${index + 1}`}
                      value={image}
                      onChange={handleChange}
                      className={styles.editableInput}
                    />
                  ) : (
                    <img src={image} alt={editableProduct.name} className={styles.productImage} />
                  )}
                </div>
              ))}
            </Carousel>
          </div>
          <div className={styles.productInfo}>
            {product.specifications && (
              <div className={styles.productSpecifications}>
                <h4>Характеристики:</h4>
                <ul className={styles.productUl}>
                  {Object.entries(product.specifications).slice(0, 7).map(([key, value]) => (
                    <li key={key}>
                      <strong>{key}:</strong> {value}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className={styles.productOrder}>
            <p className={styles.productPrice}>
              {isEditMode ? 
                <input
                  type="text"
                  name="price"
                  value={editableProduct.price}
                  onChange={handleChange}
                  className={styles.editableInput}
                /> :
                `${editableProduct.price} ₽`}
            </p>
            <button className={styles.addToCartButton}>Добавить в корзину</button>
          </div>
        </div>
      </div>
      <div className={styles.productInfo}>
        <ul className={styles.productMenu}>
          {['Описание', 'Характеристики', 'Оплата', 'Доставка / Самовывоз', 'Монтаж', 'Инструкция', 'Гарантия'].map(item => (
            <li
              key={item}
              className={activeMenuItem === item ? styles.activeMenuItem : ''}
              onClick={() => handleMenuItemClick(item)}
            >
              {item}
            </li>
          ))}
        </ul>
        <div className={styles.productMenuItem}>
          {renderContent()}
        </div>
      </div>
      {isEditMode && (
        <div className={styles.adminPanel}>
          <button onClick={saveChanges} className={styles.saveButton}>Сохранить изменения</button>
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
