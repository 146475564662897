import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from './CartContext';
import styles from '../styles/ProductCard.module.css';





const ProductCard = ({ product }) => {
  const { addToCart } = useContext(CartContext);
  const specifications = product.specifications;

  // Get the first 5 entries of the specifications object
  // const firstFiveSpecifications = specifications
  //   ? Object.entries(specifications).slice(0, 5)
  //   : [];




  return (
    <div className={styles.productWrapper}>
      <div className={styles.productCard}>
        <h3 className={styles.productName}>{product.name}</h3>
        <Link to={`/product/${product.id}`}>
          <img src={product.image1} alt={product.name} className={styles.productImage} />
        </Link>
        <div className={styles.productInfo}>

          {/* <p className={styles.productBrand}>{firstFiveSpecifications.length > 0 ? (
            <ul>
              {firstFiveSpecifications.map(([key, value], index) => (
                <li key={index}>
                  <strong>{key}:</strong> {value}
                </li>
              ))}
            </ul>
          ) : (
            <p>No specifications available.</p>
          )}</p> */}


          <p className={styles.productPrice}> Ценна {product.price}</p>
          {/* {product.specifications?.['Макс. производительность'] || 'N/A'} */}
          <button className={styles.addToCartButton} onClick={() => addToCart(product)}>Добавить в корзину</button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
