import React, { useState } from 'react';
import styles from './Header.module.css';
import SearchBar from './SearchBar';
import CategoryList from './CategoryList';

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header>
            <div className={styles.topBar}>
                <span className={styles.location}>Сочи</span>
                <span className={styles.storeCount}>4 магазина</span>
                <a href="#" className={styles.corporateLink}>Покупать как юрлицо</a>
                <a href="#" className={styles.deliveryInfo}>Получение и оплата</a>
                <a href="#" className={styles.support}>Сервис и поддержка</a>
                <a href="#" className={styles.contacts}>Контакты</a>
                <span className={styles.phoneNumber}>8 800 550-37-71</span>
            </div>
            <div className={styles.mainBar}>
                <div className={styles.logo}>
                    <a href="/home"><img src="logo.png" alt="Логотип"></img></a>
                    <span className={styles.logoText}>goClimate</span>
                </div>
                {/* Burger Menu Button */}
                <button className={styles.burgerButton} onClick={toggleMenu}>
                    <div className={styles.burgerIcon}></div>
                </button>
                <div className={styles.searchBar}>
                    <SearchBar />
                </div>
                <div className={styles.userLink}>
                    <a href="/cart" class="cart"><i class="fas fa-shopping-cart"></i> Корзина</a>
                    <a href="/register" class="comparison"><i class="fas fa-exchange-alt"></i>Регистрация</a>
                    <a href="/login" class="login"><i class="fas fa-user"></i> Войти</a>
                </div>
            </div>

            {/* Menu (Hidden on Desktop, Visible on Mobile) */}
            <nav className={`${styles.mainNav} ${isMenuOpen ? styles.open : ''}`}>


                <div className={styles.navLinks}>
                    <a href="/admin">Админ панель</a>
                    <a href="#">Монтаж</a>
                    <a href="#">Доставка</a>
                    <a href="#">Оплата</a>
                </div>

                <div className={styles.catalogButton}>
                    <CategoryList />

                </div>
            </nav>
        </header>
    );
}
