// src/components/SearchBar.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import products from '../data/products';
import styles from '../styles/SearchBar.module.css';

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length >= 3) {
      const searchTerms = value.toLowerCase().split(' ');

      const filteredProducts = products.filter(product =>
        searchTerms.every(term => product.name.toLowerCase().includes(term))
      ).slice(0, 10);

      setSuggestions(filteredProducts);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = () => {
    setQuery('');
    setSuggestions([]);
  };

  const handleSearch = () => {
    if (query.length >= 3) {
      setSuggestions([]); // Очистка подсказок
      navigate(`/search?query=${encodeURIComponent(query)}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={styles.searchBar}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Поиск товаров..."
        className={styles.searchInput}
      />
      <button onClick={handleSearch} className={styles.searchButton}>
        Поиск
      </button>
      {suggestions.length > 0 && (
        <ul className={styles.suggestionsList}>
          {suggestions.map(suggestion => (
            <li key={suggestion.id} className={styles.suggestionItem}>
              <Link to={`/product/${suggestion.id}`} onClick={handleSuggestionClick}>
                {suggestion.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
