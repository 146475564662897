// src/components/Filters.js
import React, { useState, useEffect } from 'react';
import styles from '../styles/Filters.module.css';

const Filters = ({ products, onFilterChange }) => {
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');

    useEffect(() => {
        const uniqueBrands = Array.from(new Set(products.map(product => product.brand)));
        setBrands(uniqueBrands);
    }, [products]);

    const handleBrandChange = (brand) => {
        setSelectedBrands(prevSelectedBrands => {
            const newSelectedBrands = prevSelectedBrands.includes(brand)
                ? prevSelectedBrands.filter(b => b !== brand)
                : [...prevSelectedBrands, brand];
            onFilterChange({ brands: newSelectedBrands, minPrice, maxPrice });
            return newSelectedBrands;
        });
    };

    const handleMinPriceChange = (e) => {
        const value = e.target.value;
        setMinPrice(value);
        onFilterChange({ brands: selectedBrands, minPrice: value, maxPrice });
    };

    const handleMaxPriceChange = (e) => {
        const value = e.target.value;
        setMaxPrice(value);
        onFilterChange({ brands: selectedBrands, minPrice, maxPrice: value });
    };

    return (
        <div className={styles.filters}>
            <div className={styles.filterSection}>
                <h3>Бренд</h3>
                {brands.map(brand => (
                    <label key={brand}>
                        <input
                            type="checkbox"
                            value={brand}
                            checked={selectedBrands.includes(brand)}
                            onChange={() => handleBrandChange(brand)}
                        />
                        {brand}
                    </label>
                ))}
            </div>
            <div className={styles.filterSection}>
                <h3>Цена</h3>
                <label>
                    Мин. цена
                    <input
                        type="number"
                        value={minPrice}
                        onChange={handleMinPriceChange}
                        className={styles.priceInput}
                    />
                </label>
                <label>
                    Макс. цена
                    <input
                        type="number"
                        value={maxPrice}
                        onChange={handleMaxPriceChange}
                        className={styles.priceInput}
                    />
                </label>
            </div>
        </div>
    );
};

export default Filters;
