// src/components/SearchResults.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import products from '../data/products';
import ProductCard from './ProductCard';
import styles from '../styles/SearchResults.module.css';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchResults = () => {
  const query = useQuery().get('query').toLowerCase();
  const searchTerms = query.split(' ');

  const filteredProducts = products.filter(product =>
    searchTerms.every(term => product.name.toLowerCase().includes(term))
  );

  return (
    <div className={styles.searchResults}>
      <h1>Результаты поиска для "{query}"</h1>
      <div className={styles.productList}>
        {filteredProducts.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default SearchResults;
