import React from 'react';
import styles from './Home.module.css'; // Импортируем стили для страницы
import MainCategories from '../components/MainCategories';






// Главная страница
function Home() {
  return (
    <div className={styles.home}>
      <h2>Добро пожаловать в наш интернет-магазин</h2>
     
      <MainCategories />
     
     

    </div>




  );

}

export default Home;
