import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import products from '../data/products';
import ProductCard from './ProductCard';
import Breadcrumbs from './Breadcrumbs';

import styles from '../styles/ProductCard.module.css';

const Category = () => {
  const { mainCategory, subCategory } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const filteredProducts = products.filter(product => {
    const categoryName = product.categoryName[mainCategory];
    return categoryName === subCategory;
  });

  const displayedProducts = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 1; // Количество страниц в начале
    const pagesAroundCurrent = 2; // Количество страниц вокруг текущей

    if (totalPages <= maxPagesToShow + 2) {
      // Если страниц мало, отображаем все
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? styles.activePage : ''}
          >
            {i}
          </button>
        );
      }
    } else {
      // Первые несколько страниц
      for (let i = 1; i <= maxPagesToShow; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? styles.activePage : ''}
          >
            {i}
          </button>
        );
      }

      // Промежуточные страницы вокруг текущей
      if (currentPage > maxPagesToShow + 1) {
        pages.push(<span key="dots1">...</span>);
      }

      const startPage = Math.max(maxPagesToShow + 1, currentPage - pagesAroundCurrent);
      const endPage = Math.min(totalPages - 1, currentPage + pagesAroundCurrent);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? styles.activePage : ''}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages - 1) {
        pages.push(<span key="dots2">...</span>);
      }

      // Последняя страница
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={currentPage === totalPages ? styles.activePage : ''}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className={styles.categoryPage}>
      <Breadcrumbs />
      <h1>{mainCategory} - {subCategory}</h1>
      <div className={styles.productList}>
        {displayedProducts.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <div className={styles.pagination}>
        {renderPagination()}
      </div>
    </div>
  );
};

export default Category;
