import React, { useContext } from 'react';
import { CartContext } from '../components/CartContext';
import styles from './Cart.module.css';
import ContactForm from '../components/ContactForm';

function Cart() {
  const { cart, removeFromCart, updateCartItemQuantity } = useContext(CartContext);

  // Расчет общей суммы
  const totalAmount = cart.reduce((total, product) => {
    // Преобразуем цену в число на случай, если она хранится как строка
    const productPrice = parseFloat(product.price) || 0;
    return total + productPrice * product.quantity;
  }, 0);

  return (
    <div className={styles.cart}>
      <h2>Корзина</h2>
      {cart.length === 0 ? (
        <p>Ваша корзина пуста</p>
      ) : (
        <div className={styles.cartDetails}>
          <ul className={styles.cartList}>
            {cart.map((product) => (
              <li key={product.id} className={styles.cartItem}>
                <span className={styles.productCode}>Код: {product.sku}</span>
                <img src={product.image1} alt={product.name} className={styles.productImage} />
                <span className={styles.productName}>Имя: {product.name}</span>
                <span className={styles.productPrice}>{product.price} ₽</span>
                <div className={styles.quantityControls}>
                  <button onClick={() => updateCartItemQuantity(product.id, -1)}>-</button>
                  <span>{product.quantity}</span>
                  <button onClick={() => updateCartItemQuantity(product.id, 1)}>+</button>
                </div>
                <button onClick={() => removeFromCart(product.id)} className={styles.removeButton}>Удалить</button>
              </li>
            ))}
          </ul>
          {/* Блок общей суммы */}
          <div className={styles.totalAmount}>
            <h3>Общая сумма: {totalAmount.toFixed(2)} ₽</h3>
          </div>
          <ContactForm />
        </div>
      )}
    </div>
  );
}

export default Cart;
